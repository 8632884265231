.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: rgba(0,0,0,.5);
    z-index: 100;
}

.modal {
    position: relative;
    display: inline-block;
    top: 2em;
    left: 20%;
    width: 60%;
    max-height: 80%;
    text-align: center;
    background: white;
    border-radius: 10px;
    border: 5px solid #ffffff;
    border-top-width: 2em;
    overflow-y: scroll;
    padding-bottom: 1em;
    color: black;
    
    -webkit-box-shadow: -7px 12px 19px -5px rgba(0,0,0,0.27);
    -moz-box-shadow: -7px 12px 19px -5px rgba(0,0,0,0.27);
    box-shadow: -7px 12px 19px -5px rgba(0,0,0,0.27);
}

.modal-content {
    width: 90%;
    margin-left: 5%;
}

.modal p{
    display: inline-block;
    width: 96%;
    /* padding-left: 2%; */
    /* padding-top: 1em; */
    /* padding-bottom: 1em; */
    
}

.modal .modal-close-button{
    position: fixed;
    display: inline-block;
    right: 19.5%;
    top: 2.2em;
    background: rgb(180, 180, 180);
    color: white;
    font-weight: bold;
    z-index: 4;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.modal .modal-ok-button {
    color: white;
    border-radius: 5px;
    width: auto;
    padding: .5em 2em;
    text-align: center;
    background: gray;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: .5em;
}






@media screen and (max-width: 720px) {

    .modal {
        /* right: 10%; */
        width: 90%;
        left: 2%;
        z-index: 1;
    }

    .modal .modal-close-button {
        right: 6%;
    }

}